.terms-of-services table {
    text-align: center;
    margin: 0 auto;
}
.terms-of-services table tr td,
.terms-of-services table tr th {
    border: 1px solid rgba(0, 0, 0, 0.08);
    letter-spacing: 0em;
    line-height: 1.42;
    padding: 10px;
} 

.terms-of-services table tr th {
    font-weight: 700;
    text-align: center;
}

.terms-of-services table tr:nth-child(2n) td {
    background: rgba(0, 0, 0, 0.01);
}