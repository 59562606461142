/* @media screen and (max-width: 400px) {
    .topbar-width400 {
      width: 100%!important;
    }
  } */

  @media only screen and (max-width: 900px) {
    /* / styles for browsers larger than 1440px; / */
    .container-fluid{
      display: none;
    }
   
  }
  @media only screen and (min-width: 2000px) {
    /* / for sumo sized (mac) screens / */
   
  }
  @media only screen and (max-device-width: 480px) {
   /* / styles for mobile browsers smaller than 480px; (iPhone) / */
  
  }
  @media only screen and (device-width: 768px) {
   /* / default iPad screens / */
  
  }
  /* / different techniques for iPad screening / */
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* / For portrait layouts only / */
  
  }
  
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* / For landscape layouts only / */
  
  }