.Nav{
    transition:0.5s;
    background-color: #E9FCFF;
    padding: 1rem ;
    position: sticky ;
    top: 0 ;
}
.Nav-bar-submenu{
    text-decoration: none;
    color: black!important;
    font-weight: 500!important;
    
}
.nav_black{
    background-color: white ;
    transition: all 0.5s ease-in-out;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: fixed;
    color: black;
    }
.vr{
    border: 1px solid;
    margin-left: 50px;
    color: #00AEEF; 
}
.nav-search{
    border-radius: 30px!important; 
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.nav-search-margin{
    padding-right: 40px;
}

.nav-text-color{
    color: black!important;
   font-family: 'Lato', sans-serif;
    font-weight: 500!important;
    font-size: 16px!important;
    text-decoration: none;
}
.nav-home-margin{
    margin-top: 2px;
}
.nav-text-color li.active { 
    color:rgb(14, 253, 241)!important;
 }

.tab:hover,
.tab:active {
  color: #00AEEF!important;
}
.Nav-color-sub{
    color:  black!important;
}

@media screen and (max-width: 400px) {
    .nav-search-margin{
        margin-left: 55px;
        width: 300px;
        text-align: center;
    }
  }

  @media screen and (max-width: 428px) {
    .nav-search-margin{
        margin-left: 60px;
        width: 300px;
        text-align: center;
    }
  }

  @media screen and (max-width: 375px) {
    .nav-search-margin{
        margin-left: 40px;
        width: 300px;
        text-align: center;
    }
  }




#nav-dropdown{
    color: black!important;
   font-family: 'Lato', sans-serif;
    font-weight: 500!important;
    font-size: 16px!important;
    text-decoration: none;
}
#nav-dropdown:hover{
    color: #00AEEF!important;
}
/* #nav-dropdown:active{
    color: #00AEEF!important;
} */

.top-logo-size-control{
    padding: 10px 10px 15px 25px;
}

input[type="search"] {
    line-height: 25px;
    margin: 0;
    font-size: 14px;
    box-shadow: 0 0 2px transparent !important;  

    border: none !important;
    border-color: transparent !important;
 
  }
  input[type="search"]:focus{
    border: none !important;
    border-color:  transparent !important;
  }
  .nav-search {
 
    background: white !important;
    width: auto;

}
input {

    border-color: transparent!important;
outline: transparent !important;
    border: 0.2px solid transparent !important;
}
  form {
    display: flex;
    justify-content: center !important;
    border-radius: 5px;
    width: 100%;
    
}

form input, form textarea {
    padding: 0 10px;
}
.search-icon{
    overflow: hidden;
    margin: auto;
  }
  
  .nav-text-color:hover {
    color: #00AEEF !important;
}
.nav-text-color:active{
    color: #00AEEF!important;
}
.nav-text-color:focus{
    color: #00AEEF !important;
}

a.nav-dropdown{
    color: #00AEEF !important;
}
a.nav-dropdown:active{
    color: #00AEEF !important;
}
a.nav-dropdown:focus{
    color: #00AEEF !important;
}
/* .Nav-bar-submenu:hover {
    color: #00AEEF !important;
    background-color: transparent !important;
}
.Nav-bar-submenu:focus{
    color: #00AEEF !important;
    background-color: transparent !important;
}
.Nav-bar-submenu {
    color: black!important;
    background-color: transparent !important;
} */
/* #nav-dropdown , .Nav-bar-submenu:active{
    color: #00AEEF !important;
} */


.dropdown-menu {


    --bs-dropdown-link-active-color: #00AEEF !important ;
    --bs-dropdown-link-active-bg: #ffff !important;

 
    background-color: var(--bs-dropdown-bg);
    color: var(--bs-dropdown-link-active-color); 
}





@media  screen and (min-width: 400px) {
    /* / styles for browsers larger than 1440px; / */
    
    /* .dropdown-menu.show {
        background-color: #e9fcff !important  ;
        border:none !important; 
        text-align: center !important;       
    } */
    /* .dropdown-menus {
        background-color: var(--bs-dropdown-bg);
    color: var(--bs-dropdown-link-active-color);
    } */
   

    
  }
