.company-gradiant{
    background-image: url(../../assets/image/company-bg.jpg);
    /* background-image: -moz-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -webkit-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -ms-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image:  linear-gradient(180deg, #85dfff 0%, #00aeef 100%); */
    position: static;
    z-index: 1;
    padding: 160px 0px 125px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .company-gradiant1{
    background-image: url(../../assets/image/new2.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 500px 520px;
    /* background-image: -moz-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -webkit-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -ms-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image:  linear-gradient(180deg, #85dfff 0%, #00aeef 100%); */

  }
  .text-develop-p{
   color: white; 
  }

   /* animation css  */

   .f_c {
      font-family: 'Lato', sans-serif;

  }

  .c_700 {
    font-weight: 700;
  }  
  .c_size_50 {
    font-size: 50px;
  }
  .c_color {
    color: #fff;
  }
  .c_height50 {
    line-height: 50px;
  }
.mc_20 {
    margin-bottom: 20px;
  }

  /* animation css  */

.heading-company {
    font-size: 52px;
    line-height: 1.425;
    word-wrap: break-word;
      font-family: 'Lato', sans-serif;

}

.company-img-width{
  width: 450px!important;
  float: left;
}
.develop-span{
  color: #00aeef; 
 }
 .selector-width{
  width: 260px;
  margin-left: 15px;
  border: 1px solid #00aeef;
 }
 .selector-border{
  border: 1px solid #00aeef!important;
 }
 .button-background{
  background-color: #00aeef!important;
  color: white!important;
  width: 485px;
  height: 50px;
  /* margin-left: 30px; */
 }
 .button-background:hover{
  background-color: #0010e9!important;
 }
 .email-input{
  margin-left: 15px!important;
 }
 .book-text{
  font-weight: 550;
 }

 .joint-text-size{
    font-family: 'Lato', sans-serif;

  font-size: 24px;
  text-align: center;
}
.joint-span{
    color: #00aeef;
}

h3.resource-heading {
  color: #ababab;
}

.resource-bg {
  background-image: url(../../assets/image/mitech-slider-cybersecurity-global-image.png);
  background-repeat: no-repeat;
  background-position: right;
}

@media screen and (max-width: 400px) {

  .homepage.company-gradiant1 {
    background: none;
  }

  .resource-bg .button-margin {
    -webkit-order: 3;
      -moz-order: 3;
      order: 3;
  }


  .button-background{
    background-color: #00aeef!important;
    color: white!important;
    width: 365px;
    height: 50px;
   }
  
  }

  @media screen and (max-width: 375px) {

    .button-background{
      background-color: #00aeef!important;
      color: white!important;
      width: 340px;
      height: 50px;
      /* margin-left: 8px; */
     }
    
    }
