
.footer-link{
    text-decoration: none;
   font-family: 'Lato', sans-serif;
}
.ddevops-span{
    color: #00AEEF;
   font-family: 'Lato', sans-serif;

}
.text-deco{
  text-decoration: none;

}
.com-span{
    color: #80758F;
   font-family: 'Lato', sans-serif;
}
.footer-watermark{
    background-image: url(../../assets/image/1x/water-mark.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #b2e7fa;
}
.tooltip-btn{
color: gray !important;

}
.tooltip-btn:hover{
 color: #00AEEF !important;
}
#tooltip-top > .tooltip-inner {
    background-color: #00AEEF;
    color: white;
  }
  
  #tooltip-top > .tooltip-arrow {
    
    /* background-color:#00AEEF ; */
    color: #00AEEF;
  }

  .btn {
    --bs-btn-color: grey !important;
    border: none !important;
}
.btn:hover{
    border: none ;
}

.footer-hover:hover{
  color: #00AEEF!important;
}



/* animater footer  */


/*============ new_footer_area css ===========*/

.new_footer_top {
    padding: 100px 0px 70px;
    position: relative;
}

.new_footer_area {
    background: transparent ;
  }
  
  .new_footer_area .footer_bottom {
    padding-top: 10px;
    padding-bottom: 50px;
    
  }
  
  .new_footer_area .footer_bottom p {
    font-size: 16px;
    color: #6a7695;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  .new_footer_area .footer_bottom p i {
    color: #fd2f51;
  }
  
  /* .new_footer_top {
    padding: 120px 0px 270px;
    position: relative;
  } */
  
  .new_footer_top .f-title {
    margin-bottom: 30px;
    color: #263b5e;
  }
  
  .new_footer_top .company_widget p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #6a7695;
    margin-bottom: 20px;
  }
  
  .new_footer_top .company_widget .f_subscribe_two .form-control {
    border: 1px solid #e2e2eb;
    border-radius: 4px;
    height: 55px;
    background: #fff;
    font-size: 15px;
    font-weight: 300;
    line-height: 55px;
    padding-left: 30px;
  }
  
  .new_footer_top .company_widget .f_subscribe_two .btn_get {
    border-width: 1px;
    margin-top: 20px;
  }
  
  .new_footer_top .f_widget.about-widget .f_list li {
    margin-bottom: 11px;
  }
  
  .new_footer_top .f_widget.about-widget .f_list li a {
    color: #6a7695;
  }
  
  .new_footer_top .f_widget.about-widget .f_list li a:before {
    display: none;
  }
  
  .new_footer_top .f_widget.about-widget .f_list li a:hover {
    color: #5e2ced;
  }
  
  .new_footer_top .f_social_icon a {
    width: 44px;
    height: 44px;
    line-height: 43px;
    background: transparent;
    border: 1px solid #e2e2eb;
    font-size: 12px;
  }
  
  .new_footer_top .f_social_icon a:hover {
    background: #5e2ced;
    border-color: #5e2ced;
  }
  
  .new_footer_top .f_social_icon a + a {
    margin-left: 4px;
  }
  
  .new_footer_top .footer_bg {
    position: absolute;
    bottom: 0!important;
    /* background: url("../img/seo/footer_bg.png") no-repeat scroll center 0; */
/*   
    background-image:url(../../assets/image/footer/footer_bg.png) ; */
    background-image:url("../../assets/image/footer/footer_bg.png")  ;
    background-size: cover;
    background-repeat:no-repeat;
    width: 100%;
    height: 246px;
       }
  
  
  /* images of  cycle and car  */
  .new_footer_top .footer_bg .footer_bg_one {
    /* background: url("../img/seo/car.png") no-repeat center center; */
    background-image:url("../../assets/image/footer/car1.png") ;
    background-position:center ;
    background-repeat:no-repeat;
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 0 !important;
    left: 30%;
    -webkit-animation: myfirst 22s  linear infinite;
    animation: myfirst 22s  linear infinite;
  }
  .new_footer_top .footer_bg .footer_bg_one {
    /* background: url("../img/seo/car.png") no-repeat center center; */
    background-image:url("../../assets/image/footer/car2.png") ;
    background-position:center ;
    background-repeat:no-repeat;
    width: 110px;
    height: 60px;
    position: absolute;
    bottom: 0 !important;
    left: 60%;
    -webkit-animation: myfirst 22s  linear infinite;
    animation: myfirst 22s  linear infinite;
  }
  .new_footer_top .footer_bg .footer_bg_two {
    /* background: url("../img/seo/bike.png") no-repeat center center; */
    background-image:url(../../assets/image/footer/bike.png) ;
    width: 50px;
    height: 52px;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 30s  linear infinite;
    animation: myfirst 30s  linear infinite;
  }
  /* images of  cycle and car  */
  
  @-moz-keyframes myfirst {
    0% {
      left: -25%;
    }
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes myfirst {
    0% {
      left: -25%;
    }
    100% {
      left: 100%;
    }
  }
  
  @keyframes myfirst {
    0% {
      left: -25%;
    }
    100% {
      left: 100%;
    }
  }
  
  @media only screen and (min-width: 2000px) {
}
  