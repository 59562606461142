.tabs-control{ 
     width: 50%;
     margin-left: 25%; 
 }
 
.tabs-font-size{
      font-family: 'Lato', sans-serif;

 }
 
 .tab-color{
     color: #00AEEF;
 }
 
 .myclass{
     color: black;
     /* background-color: #292929 !important; */
 }
 .myclass:hover{
     color: white !important;
     background-color: #00AEEF !important;
 }
 .myclass:active{
     color: white !important;
     background-color: #00AEEF !important;
 }
 .myclass:focus{
    color: white !important;
     background-color: #00AEEF !important;

}
 
 .key-color{

    color: #212529!important;
      font-family: 'Lato', sans-serif;


 }
 
 .tab-responsive {
     display: inline-block;
     -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
     transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
   }
 
 .tab-responsive::after {
     position: absolute;
     z-index: -1;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
     opacity: 0;
     -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
     transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
   }
 
 .tab-responsive:hover {
     -webkit-transform: scale(1.4 , 1.5);
     transform: scale(1.1 , 1.1);
   }
   
 .tab-responsive:hover::after {
       opacity: 1;
   }
 
 .tab-bg-color{
    background-color: #E9FCFF ;
}

 @media screen and (max-width: 600px) {
       .img-control {
    width: 100%;
    height: 95%;
 }
 
 .tabs-control{ 
     width: 100%; 
     margin-left: -1%;
 }
     }

    @media screen and (max-width: 400px) {
 .image-responsive{
    /* width: 370px; */
    display: none;
 }
      }

    @media screen and (max-width: 412px) {
        .image-responsive{
           /* width: 370px; */
           display: none;
        }
             }