.agency_featured_item {
    margin-top: 130px;
  }
  
  .agency_featured_item .agency_featured_content {
    position: relative;
  }
  
  .agency_featured_item .agency_featured_content h3 {
    font: 500 26px/36px 'Lato', sans-serif;
    color: #222d39;
    margin: 32px 0px 25px;
  }
  
  .agency_featured_item .agency_featured_content p {
    font-size: 15px;
  }
  
  .agency_featured_item .agency_featured_content .icon {
    width: 46px;
    height: 46px;
    line-height: 46px;
    border-radius: 50%;
    background-image: -moz-linear-gradient(40deg,  #00aeef 0%, #85dfff 100%);
    background-image: -webkit-linear-gradient(40deg,  #00aeef 0%, #85dfff 100%);
    background-image: -ms-linear-gradient(40deg,  #00aeef 0%, #85dfff 100%);
    background-image: linear-gradient(40deg, #00aeef 0%, #85dfff 100%);
    -webkit-box-shadow: 0px 10px 20px 0px rgba(44, 182, 237, 0.2);
    box-shadow: 0px 10px 20px 0px rgba(44, 182, 237, 0.2);
    font-size: 20px;
    color: #fff;
    display: inline-block;
    text-align: center;
  }
  
  .agency_featured_item.agency_featured_item_two .agency_featured_content .dot {
    left: 30px;
  }

  .agency_featured_item .agency_featured_content {
    position: relative;
  }
  
  .agency_featured_item .agency_featured_content h3 {
    font: 500 26px/36px 'Lato', sans-serif;
    color: #222d39;
    margin: 32px 0px 25px;
  }
  
  .agency_featured_item .agency_featured_content p {
    font-size: 15px;
  }
  
  .agency_featured_item .agency_featured_content .icon {
    width: 46px;
    height: 46px;
    line-height: 46px;
    border-radius: 50%;
    background-image: -moz-linear-gradient(40deg,  #00aeef 0%, #85dfff 100%);
    background-image: -webkit-linear-gradient(40deg,  #00aeef 0%, #85dfff 100%);
    background-image: -ms-linear-gradient(40deg, #00aeef 0%, #85dfff 100%);
    background-image: linear-gradient(40deg, #00aeef 0%, #85dfff 100%);
    -webkit-box-shadow: 0px 10px 20px 0px rgba(44, 182, 237, 0.2);
    box-shadow: 0px 10px 20px 0px rgba(44, 182, 237, 0.2);
    font-size: 20px;
    color: #fff;
    display: inline-block;
    text-align: center;
  }
  
  .agency_featured_item.agency_featured_item_two .agency_featured_content .dot {
    left: 30px;
  }

  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(255, 161, 39, 0.161);
    display: block;
    position: absolute;
    left: -9px;
    top: 15px;
    z-index: 1;
  }
  
  .dot .dot1 {
    position: absolute;
    left: 50%;
    margin-top: -4px;
    margin-left: -4px;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #feb85d;
  }
  
  .dot .dot2 {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(35, 189, 250, 0.8);
    -webkit-animation: pulsate 3s infinite;
    animation: pulsate 3s infinite;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  .progress_bar_area .number {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #051441;
    font-family: 'Lato', sans-serif;

    font-weight: 400;
  }

  .stratup_service_info .startup_service_item .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    color: #fff;
    background: #6fadfa;
    line-height: 65px;
    position: absolute;
    left: 0;
    top: -5px;
  }
  
  .stratup_service_info .startup_service_item .icon.icon_two {
    background: #f3af4e;
  }
  
  .stratup_service_info .startup_service_item .icon.icon_three {
    background: #fa6fd1;
  }
  
  .stratup_service_info .startup_service_item .icon.icon_four {
    background: #fa6666;
  }

  .mt_30 {
    margin-top: 30px;
  }

  .ti-arrow-right:before {
    content: ">";
    text-decoration: none!important;
  }
  .arrow-text{
    text-decoration: none!important;
  }