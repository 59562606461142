.develop-tabs-control{ 
    width: 50%;
    margin-left: 25%; 
}

.develop-tabs-font-size{
     font-family: 'Lato', sans-serif;

}

.develop-tab-color{
    color: #00AEEF;
    font-size: 2rem;
}

.develop-myclass{
    color: black;
    /* background-color: #292929 !important; */
}
.develop-myclass:hover{
    color: white !important;
    background-color: #00AEEF !important;
}
.develop-myclass:active{
    color: white !important;
    background-color: #00AEEF !important;
}
.develop-myclass:focus{
   color: white !important;
    background-color: #00AEEF !important;

}

.develop-key-color{

   color: #212529!important;
     font-family: 'Lato', sans-serif;


}


@media screen and (max-width: 600px) {
      .img-control {
   width: 100%;
   height: 95%;
}

.develop-tabs-control{ 
    width: 100%; 
    margin-left: -1%;
}
    }

    @media screen and (max-width: 400px) {
.image-responsive{
   /* width: 370px; */
   display: none;
}
     }