  .body_wrapper{
    z-index: 20;
    overflow: hidden;
  }

  .partnership-gradiant img {
    width: 100%;
    display: block;
  }
  
  .breadcrumb_area .breadcrumb_shap {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .breadcrumb_area p{
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
  }

  .bg_color {
    background: #fbfbfd;
  }

  .sec_pad {
    padding: 70px 0px;
  }

  .feature-text{
    margin-left: 10px!important;
  }
  .feature-text2{
    margin-left: 170px!important;
    margin-top: 150px!important;
  }
  .feature-text3{
    margin-top: 230px!important;
    margin-left: 10px!important;

  }

  .feature-text4{
    margin-top: 150px!important;
    margin-left: 170px!important;

  }

  .feature-text5{
    margin-top: 270px!important;
    margin-left: 10px!important;

  }
  .feature-text6{
    margin-top: 230px!important;
    margin-left: 170px!important;
  }

  .features_info {
    position: relative;
  }
  
  .features_info .dot_img {
    position: absolute;
    left: 0;
  }
  
  .features_info.feature_info_two {
    padding-bottom: 70px;
  }

  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color:#d1effa!important;
    display: block;
    position: absolute;
    left: -30px!important;
    top: 15px;
    z-index: 1;
  }
  
  .dot .dot1 {
    position: absolute;
    left: 50%;
    margin-top: -4px;
    margin-left: -4px;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #99eff1;
  }
  
  .dot .dot2 {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(128, 200, 209, 0.8);
    -webkit-animation: pulsate 3s infinite;
    animation: pulsate 3s infinite;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    will-change: transform;
  }
  .dot.middle_dot {
    left: 54.8%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    top: auto;
  }

  .h_map ul li .round .dot {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: rgba(247, 98, 106, 0.35);
    -webkit-animation: pulsate 3s infinite;
    animation: pulsate 3s infinite;
    -webkit-animation-delay: 1.5s;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    animation-delay: 1.5s;
    will-change: transform;
  }

  .flex-row-reverse .erp_features_img_two .img_icon {
    left: -53px;
    right: auto;
  }
  
  .flex-row-reverse .erp_content_two {
    padding-right: 125px;
    padding-left: 0;
  }

  .h_analytices_features_item.flex-row-reverse .h_security_img:before {
    left: auto;
    right: 20px;
    background: #edb4cb;
  }
  
  .setup_inner .setup_item.flex-row-reverse .setup_content {
    text-align: right;
    padding-right: 30px;
    padding-left: 0;
  }
  
  .h_analytices_features_item.flex-row-reverse .h_security_img img {
    -webkit-box-shadow: 15px 25.981px 60px 0px rgba(1, 1, 64, 0.14);
    box-shadow: 15px 25.981px 60px 0px rgba(1, 1, 64, 0.14);
  }

  .app_img .dot {
    background: rgba(64, 105, 235, 0.161);
  }
  
  .app_img .dot .dot1 {
    background: #6c8bed;
  }
  
  .app_img .dot .dot2 {
    background: rgba(64, 105, 235, 0.8);
  }
 
  .breadcrumb_area .breadcrumb_shap {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .breadcrumb_area_three .breadcrumb_shap{
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  .breadcrumb_area_two .breadcrumb_shap {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    height: 100%;
  }

  .span-color{
    color: #00aeef;
    font-family: 'Lato', sans-serif;
  }
  .business-font{
    font-family: 'Lato', sans-serif;
  }
  .business-p{
   margin-left: 30px; 
  }

@media screen and (max-width: 400px) {

  .business-p{
    margin-left: 0px; 
   }

  .feature-text{
    margin-left: -20px!important;
    text-align: center;
  }
  .dot_img {
    position: absolute;
    left: 0;
    display: none;
  }
  .feature-text2{
    margin-left: -20px!important;
    text-align: center;
    margin-top: -10px!important;
  }
  .feature-text3{
    margin-left: -20px!important;
    text-align: center;
    margin-top: -10px!important;
  }
  .feature-text4{
    margin-left: -20px!important;
    text-align: center;
    margin-top: -10px!important;
  }
  .feature-text5{
    margin-left: -20px!important;
    text-align: center;
    margin-top: -10px!important;

  }
  .feature-text6{
    margin-left: -20px!important;
    text-align: center;
    margin-top: -10px!important;
  }

  .busniness-text-400{
    text-align: center;
  }

}