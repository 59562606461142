.service-span{
    color: #00AEEF;
  }

  .heading-service{
    font-family: 'Lato', sans-serif;
    text-align: center;
  }
  .heading-text{
    margin-top: 100px!important;
    font-family: 'Lato', sans-serif;
    /* text-align: left;   */
    line-height: 25px;
  }
  /* .service-gradiant{
    background: rgb(222,253,251);
    background: linear-gradient(174deg, rgba(222,253,251,1) 30%, rgba(222,253,251,1) 41%, rgba(222,253,251,1) 48%, rgba(255,255,255,1) 59%, rgba(255,255,255,1) 66%);
  } */
  
.server1 {   
  animation-name: server1; 
  animation-duration: 3s; 
  animation-iteration-count: infinite; 
  animation-timing-function: ease-in-out; 
  color: white;
} 
.watermark-img{
  background-image: url(../../assets/image/1x/water-mark.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200px 200px ;
}
.logo-service{
  background-image: url(../../assets/image/1x/logo-2.png);
  background-repeat: no-repeat;
  background-size: 140px;
  background-position: right;
}
@media screen and (max-width: 400px) {
  .heading-text{
    margin-top: 10px!important;
    font-family: 'Lato', sans-serif;
  }
  .img-center{
    text-align: center;
  }
  .logo-img-size400{
    background-image: url(../../assets/image/1x/logo-2.png);
    background-repeat: no-repeat;
    background-size: 0px ;
    margin-top: 25px;
  }
}

@media screen and (max-width: 375px) {
  .img-center375{
    text-align: center;
  }
}
@media screen and (max-width: 428px) {
  .img-center428{
    text-align: center;
  }
  .heading-text{
    margin-top: 0px!important;
    font-family: 'Lato', sans-serif;
  }
  .watermark-img{
    background-image: url(../../assets/image/1x/water-mark.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 270px ;
  }
}