.blog-gradiant{
    background-image: url(../../assets/image/home4/banner_bg.png);
    background-image: -moz-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -webkit-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -ms-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image:  linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    position: static;
    z-index: 1;
    padding: 160px 0px 125px;
    overflow: hidden;
  }
  .text-blog-p{
   color: white; 
  }

   /* animation css  */

   .f_blog {
    font-family: 'Lato', sans-serif;
  }

  .blog_700 {
    font-weight: 700;
  }  
  .blog_size_50 {
    font-size: 50px;
  }
  .blog_color {
    color: #fff;
  }
  .blog_height50 {
    line-height: 50px;
  }
.blog_20 {
    margin-bottom: 20px;
  }

  /* animation css  */