/*============= agency_service_area css =============*/
.agency_service_area {
    padding: 135px 0px 0px;
  }
  
  .agency_service_item {
    margin-bottom: 30px;
  }
  
  .agency_service_item .icon {
    width: auto;
    height: auto;
    display: inline-block;
  }
  
  .agency_service_item .icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .agency_service_item a {
    font: 400 14px/26px "Poppins", sans-serif;
    color: #222d39;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-transition: color 0.3s linear 0s;
    -o-transition: color 0.3s linear 0s;
    transition: color 0.3s linear 0s;
  }
  
  .agency_service_item a:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #222d39;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    -webkit-transition: width 0.3s linear 0s;
    -o-transition: width 0.3s linear 0s;
    transition: width 0.3s linear 0s;
  }
  
  .agency_service_item p {
    display: inline-block;
  }
  
  .agency_service_item p i {
    vertical-align: middle;
    padding-left: 8px;
    -webkit-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  
  .agency_service_item p:hover a {
    color: #5e2ced;
  }
  
  .agency_service_item p:hover a:before {
    width: 132%;
    background: #5e2ced;
  }
  
  .agency_service_item p:hover i {
    color: #5e2ced;
    padding-left: 12px;
  }
  

  /*============= agency_about_area css ============*/
.agency_about_area .slick-dots {
    position: absolute;
    left: -48%;
    bottom: 10px;
    text-align: left;
  }
  
  .agency_about_area .slick-dots li button {
    width: 8px;
    height: 8px;
    display: block;
    background: #9c7bfb;
    border-radius: 50%;
    border: 1px solid #9c7bfb;
    padding: 0px;
    margin: 0px;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  
  .agency_about_area .slick-dots li.slick-active button {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    border-color: rgba(251, 251, 253, 0.8);
    background: transparent;
  }
  
  .about_content_left {
    padding: 0px 0px 0px 200px;
  }
  
  .about_content {
    background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
    background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
    background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
    background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
    height: 100%;
    color: #fff;
    padding: 100px;
    position: relative;
    bottom: -30px;
  }
  
  .about_content h2, .about_content p {
    color: #fff;
  }
  
  .about_btn {
    font: 500 14px "Poppins", sans-serif;
    padding: 17px 28px;
    background: #fbfbfd;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
    box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
    border-radius: 3px;
    color: #5e2ced;
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .about_btn:hover {
    color: #fff;
    background: #5e2ced;
  }
  
  .about_img {
    padding: 0px;
    position: relative;
  }
  
  .about_img .about_img_slider .about_item {
    float: left;
    position: relative;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
  }
  
  .about_img .about_img_slider .about_item:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(17, 22, 28, 0.7);
    position: absolute;
    opacity: 0;
    z-index: 0;
    -webkit-transition: opacity 0.4s linear 0s;
    -o-transition: opacity 0.4s linear 0s;
    transition: opacity 0.4s linear 0s;
  }
  
  .about_img .about_img_slider .about_item img {
    -webkit-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }
  
  .about_img .about_img_slider .about_item.w55 {
    width: 53.3%;
  }
  
  .about_img .about_img_slider .about_item.w45 {
    width: 46.7%;
  }
  
  .about_img .about_img_slider .about_item img {
    width: 100%;
    max-width: 100%;
  }
  
  .about_img .about_img_slider .about_item .about_text {
    position: absolute;
    bottom: 80px;
    padding: 0px 80px 0px 50px;
    left: 0;
    z-index: 1;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  
  .about_img .about_img_slider .about_item .about_text h5 {
    color: #fff;
  }
  
  .about_img .about_img_slider .about_item .about_text .br {
    width: 50px;
    height: 5px;
    background: #fff;
    opacity: 0.30;
    margin-bottom: 25px;
    display: block;
  }
  
  .about_img .about_img_slider .about_item .about_text.text_two {
    bottom: 30px;
  }
  
  .about_img .about_img_slider .about_item:hover:after {
    opacity: 1;
  }
  
  .about_img .about_img_slider .about_item:hover img {
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
  }
  
  .about_img .about_img_slider .about_item:hover .about_text {
    bottom: 90px;
  }
  
  .about_img .about_img_slider .about_item:hover .about_text.text_two {
    bottom: 50px;
  }
  
  .about_img .pluse_icon {
    width: 100px;
    height: 100px;
    text-align: center;
    color: #5e2ced;
    line-height: 100px;
    font-size: 22px;
    background-color: #fbfbfd;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.14);
    box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.14);
    display: inline-block;
    position: absolute;
    bottom: -60px;
    left: 0;
    z-index: 2;
  }
  

  @media screen and (max-width: 400px) {
 
         }
  /*====================================================*/
  