
.color {
    color: #00AEEF;
}

.strong{
    color: #f8f9fa;
}
.strong1{
    color: #918c8c;
}

.span1{
    color: #00AEEF;
}

.font-devops2{
      font-family: 'Lato', sans-serif;

    font-size: 65px;
    color: #00AEEF;
}

.bg-color-devops{
    background-color: #E9FCFF ;
}
.border-box-service{
    border-right: 1px dotted #918c8c;
}
