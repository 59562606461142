.custom-container{
    overflow: hidden;
    font-family: 'Lato', sans-serif;

  }
  .prototype_service_area {
      background: #fbfbfd;
      padding-bottom: 130px;
      padding-top: 20px;
    }
    
    .service_carousel {
      padding-left: 155px;
      position: relative;
      z-index: 1;
    }
    
    .service_carousel:before {
      width: 100px;
      content: "";
      height: 100%;
      right: -25px;
      top: 0;
      position: absolute;
      background: #99def7;
      opacity: 20%;
      -webkit-filter: blur(12px);
      filter: blur(12px);
      -webkit-box-shadow: 0px 100px 40px 0px rgba(251, 251, 251, 0.1);
      box-shadow: 0px 100px 40px 0px rgba(251, 251, 251, 0.1);
      z-index: 1;
    }
    
    .service_carousel .slick-list {
      padding-left: 0 !important;
    }
    
    .service_carousel .slick-slide > div {
      padding-left: 35px;
      padding-bottom: 55px;
      height: 100%;
    }
    
    .service_carousel .slick-slide .service_item {
      -webkit-box-shadow: 0px 30px 40px 0px rgba(12, 0, 46, 0.1);
      box-shadow: 0px 30px 40px 0px rgba(12, 0, 46, 0.1);
    }
    
    .service_carousel .slick-slide .service_item:hover {
      border-color: rgba(116, 68, 253, 0.2);
    }
    
    .service_carousel .slick-slide .service_item img {
      width: auto;
    }
    .service_carousel .slick-list .slick-track,.service_carousel .slick-list{
      display: flex;
    }
    .service_carousel .slick-prev,
    .service_carousel .slick-next{
      display: none !important;
    }
    .service_carousel .slick-next {
      position: absolute;
      top: 40%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      width: 56px;
      height: 56px;
      border: none;
      border-radius: 50%;
      background: #0190c4;
      -webkit-box-shadow: 0px 2px 20px 2px rgba(12, 0, 46, 0.06);
      box-shadow: 0px 2px 20px 2px rgba(12, 0, 46, 0.06);
      -webkit-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
      text-indent: -999px;
    }
    .service_carousel .slick-next:before{
      content: "<";
      font-family: 'themify';
      text-indent: 999px;
      position: absolute;
      position: absolute;
      left: 0;
      top: 0;
      text-indent: 0;
      width: 100%;
      font-size: 30px;
      color: #222d39;
      line-height: 56px;
    }
    
    .service_carousel .slick-next:hover {
      background: #00aeef !important;
      
    }
    .service_carousel .slick-next:hover:before{
      color: #fff !important;
    }
    
  
    .service_item {
  
      -webkit-box-shadow: 0px 30px 40px 0px rgba(3, 115, 156, 0.1);
      box-shadow: 0px 30px 40px 0px rgba(3, 115, 156, 0.1);
      /* padding: 47px 60px 0px;
      height: 100%; */
      width: 290px!important;
      height: 350px;
      position: relative;
      /* border: 2px solid transparent; */
      border-top: 4px solid #00aeef;
      -webkit-transition: border 0.2s linear;
      -o-transition: border 0.2s linear;
      transition: border 0.2s linear;
      cursor: pointer;
      overflow: hidden;
    }
    
    .service_item p {
      line-height: 20px;
    }
    
    .service_item:hover {
      border-color: #c4e7f7;
    }
    
    .slick-img {
      height: 80px ;
      width: 80px!important;
    }
  
    @media (max-width: 400px){
      .service_carousel .slick-next {
        position: absolute;
        top: 40%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        width: 56px;
        height: 56px;
        border: none;
        border-radius: 50%;
        background: #0190c4;
        -webkit-box-shadow: 0px 2px 20px 2px rgba(12, 0, 46, 0.06);
        box-shadow: 0px 2px 20px 2px rgba(12, 0, 46, 0.06);
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        text-indent: -999px;
        display: none!important;
      }
  
    }
  
    @media (max-width: 1550px){
      .service_carousel {
        padding-left: 70px;
    }
  
    }
  
    @media (max-width:1199px){
  
      .service_carousel .owl-stage{
        left: 0;
    }
    }
  
    @media (max-width:768px){
  
      .service_carousel{
        padding-left: 0;
    }
     .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev{
        opacity: 0;
    }
    .service_carousel:hover .owl-nav .owl-next,.service_carousel:hover .owl-nav .owl-prev{
        opacity: 1;
    }
  
    }
  
    @media (max-width:991px){
      .service_carousel:before{
        display: none;
    }
    }