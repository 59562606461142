.devsecops-font-family{
    font-family: 'Lato', sans-serif;
}

.devsecops-span{
    color: #00AEEF;
    text-align: center;
    font-size: 25px!important;
}

.devsecops-card{
    display: block;
    width: 535px;
    height: 150px!important;
}

.devsecops-animation {
    color: #212529;
    /* border: none; */
    -webkit-transition: background 1s; /* For Safari 3.0 to 6.0 */
    transition: all .1s ease-in-out;
}
.devsecops-animation:hover {
    transform: scale(1.1);
    background-color: aliceblue;
}

@media screen and (max-width: 400px) {
    .devsecops-card{
        display: block;
        width: 320px;
        height: 250px!important;
    }
    .devsecops-animation {
        color: #212529;
        /* border: none; */
        -webkit-transition: background 1s; /* For Safari 3.0 to 6.0 */
        transition: all .1s ease-in-out;
    }
    .devsecops-animation:hover {
        transform: scale(0.9);
        background-color: aliceblue;
    }
}