.white-gradiant img{
  width: 100%;
  display: block;
  }
  .text-white-p{
   color: white; 
  }

   /* animation css  */

   .f_white {
    font-family: 'Lato', sans-serif;
  }

  .white_700 {
    font-weight: 700;
  }  
  .white_size_50 {
    font-size: 50px;
  }
  .white_color {
    color: #fff;
  }
  .white_height50 {
    line-height: 50px;
  }
.white_20 {
    margin-bottom: 20px;
  }

  /* animation css  */

  .reliable-text{
    font-family: 'Lato', sans-serif;
    text-align: center;
  }

  .white-p-size{
    font-size: 18px;
    font-family: 'Lato', sans-serif;

  }
  .font-weight{
    font-weight: 600;
    font-family: 'Lato', sans-serif;

  }

  .hr-whitelabel {
    border-left: 4px solid #00aeef;
    height: 270px;
    position: absolute;
    /* left: 50%; */
    margin-left: -20px;
    top: 130;
  }

  .whitelabel-span{
    color: #00aeef;
  }

  .whitelabel-service-button{
    height: 50px;
    background-color: #00AEEF!important;
}

.whitelabel-service-button1{
  margin: 0px 5px;
    width: 180px;
    height: 50px;
    background-color: #00AEEF!important;
    color:  #fff!important;
}

.whitelabel-service-button:hover{
    background-color: aliceblue!important;
    color: #00AEEF!important;
    border: 1px solid black!important;

}

.whitelabel-service-button1:hover{
  background-color: aliceblue!important;
  color: #00AEEF!important;
  border: 1px solid black!important;

}


  @media screen and (max-width: 400px) {

    .whitelabel-service-button, .whitelabel-service-button1{
      width: 100%;
      margin: 5px 0px;
    }
    .font-weight{
      font-weight: 600;
      font-size: 18px;
      font-family: 'Lato', sans-serif;
      margin-left: 20px;
  
    }
    .hr-whitelabel {
      border-left: 4px solid #00aeef;
      height: 150px;
      position: absolute;
      left: 8%;
      /* margin-left: -20px; */
      top: 130;
    }

    .white-p-size{
      font-size: 18px;
      font-family: 'Lato', sans-serif;
      margin-top: 20px;
    }
  }