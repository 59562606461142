
.testimonial_title {
    position: relative;
    padding-bottom: 90px;
  }
  
  .testimonial_title h6 {
    font-size: 16px;
    color: #563bd1;
  font-family: 'Lato', sans-serif;
    font-style: italic;
  }

  .seo_sec_title h2 {
    font-size: 35px;
    font-weight: 600;
    line-height: 48px;
    color: #292929;
    margin-bottom: 15px;
  }
  
  .seo_sec_title p {
    font-size: 16px;
    color: #212529!important;
    margin-bottom: 0;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .stratup_testimonial_info {
    height: 450px;
    position: relative;
  }
  
  .stratup_testimonial_info:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  .testimonial_slider_four .item {
    text-align: center;
    color: #292929;
  }
  
  .testimonial_slider_four .item {
    text-align: center;
    color: #292929;
  }
  
  .testimonial_slider_four .item .author_img {

    display: inline-block;
    overflow: hidden;
    margin-bottom: 35px;
  }
  
  .testimonial_slider_four .item img {
    width: auto;
  }

  
  .testimonial_slider_four .item img {
    width: auto;
  }
  
  .testimonial_slider_four .item p {
    font-size: 18px;
    line-height: 36px;
  font-family: 'Lato', sans-serif;
    margin-bottom: 40px;
    color: #292929;
  }
  
  .testimonial_slider_four .item h5 {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
  
  .testimonial_slider_four .item h6 {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    color: #fff;
  }
  
  .testi-img12 {
    border-radius: 50%;
    height: 80px ;
    width: 80px!important ;
    margin-left: 50px;
    margin-top: 100px;
  }
  .text-margin{
    margin-left: 0px;
    margin-top: 20px;
  }

  @media screen and (max-width: 400px) {
    .testimonial_title {
        position: relative;
        padding-bottom: 0px;
        text-align: center;
        
      }
      
      .testimonial_title h6 {
        font-size: 16px;
        color: #563bd1;
      font-family: 'Lato', sans-serif;
        font-style: italic;
      }
      
      .stratup_testimonial_info {
        height: 500px;
        position: relative;
      }
      
      .stratup_testimonial_info:before {
        content: "";
        background: url("../../assets/image/testimonial_bg1.png") no-repeat scroll center 0/contain;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        display: none;
      }
    
      .testimonial_slider_four .item {
        text-align: center;
        color: #292929;
      }
      
      .testimonial_slider_four .item {
        text-align: center;
        color: #292929;

      }
      
      .testimonial_slider_four .item .author_img {
    
        display: inline-block;
        overflow: hidden;
        margin-bottom: -10px;
      }
      
      .testimonial_slider_four .item img {
        width: auto;
      }
    
      
      .testimonial_slider_four .item img {
        width: auto;
      }
      
      .testimonial_slider_four .item p {
        font-size: 15px;
        line-height: 36px;
      font-family: 'Lato', sans-serif;
        margin-bottom: 40px;
        color: #292929;

      }
      
      .testimonial_slider_four .item h5 {
        font-size: 18px;
        font-weight: 600;
        color: #292929;

      }
      
      .testimonial_slider_four .item h6 {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 0;
        color: #292929;

      }
      
      .testi-img12 {
        border-radius: 50%;
        height: 80px ;
        width: 80px!important ;
        margin-left: 20px;
        margin-top: 0px;
      }
      .text-margin{
        margin-left: 0px;
        margin-top:  5px;
      }
  }


  .testi-span {
    color: #00aeef;
  
  
  }
  .testi-text{
  text-align: left;
  font-weight: normal !important;
  }
  
  .size-testi {
    color: #00aeef;
  }
  
  .testi-gradiants {
    background-image: url(../../assets/image/1x/Asset_8.png);
    background-repeat: no-repeat;
    background-position: 370px 30px;
    background-size: 50px;
  }
  .test1-gradiants {
    background-image: url(../../assets/image/1x/Asset_7.png);
    background-repeat: no-repeat;
    background-position: 280px 60px;
    background-size: 230px 230px, cover;
    margin-top: 50px;
  }
  .test2-gradiants {
    background-image: url(../../assets/image/1x/Asset_6.png);
    background-repeat: no-repeat;
    background-position: 690px 60px;
    background-size: 130px 130px;
    /* margin-top: 80px; */
    padding-top: 5px;
   
  }
  
  .testi-card {
    display: block;
    padding: 15px 0px;
    border-radius: 40px 40px 40px 40px !important;
    box-shadow: 0 1px 3px rgba(44, 230, 205, 0.12),
      0 1px 2px rgba(9, 231, 202, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22); */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .testi-card-animation {
    color: #212529;
    border: none;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 1s; /* For Safari 3.0 to 6.0 */
    transition: all 0.1s ease-in-out;
  }
  /* .testi-card-animation:hover {
    transform: scale(1.1);
  } */
  
  .testi-img {
    border-radius: 50%;
    height: 35px ;
    width: 35px ;
    margin-left: 0px;
  }
  .testi-img2 {
    height: 15px;
    margin-top: 8px;
  }
  .testi-text {
    font-size: 12px;
    font-weight: 700;
  }
  .carousel .slide img.testi-img2 {
    width: 50% !important;
    height: 15px;
    vertical-align: top;
    border: 0;
    display: flex;
    margin-top: 8px;
  }
  .carousel .slide img.testi-img{
    border-radius: 50%;
    height: 35px ;
    width: 35px ;
    margin-left: 15px;
  }
  .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    opacity: 1;
    /* color: blue; */
    background-color: #00aeef !important;
    filter: alpha(opacity=100);
  }
  li.dot{
    background-color: #00aeef !important;
    width: 20px;
    height: 20px;
  }
  .carousel .carousel-status {
   
    display: none !important;
  }
  .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    width: 15px !important;
    height: 15px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  /* ul.control-dots{
    background-color: #00aeef !important;
  } */
  
  .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px!important;
    height: 10px!important;
    border-radius: 50%;
    content: '';
    background: #00aeef!important;
    text-align: center;
  }
  
  @media screen and (max-width: 400px) {
    .testi-responsive-400 {
      background-image: url(../../assets/image/1x/Asset_8.png);
      background-repeat: no-repeat;
      background-position: 0px 0px;
      background-size: 70px;
    }
    .test1-responsive-400 {
      background-image: url(../../assets/image/1x/Asset_7.png);
      background-repeat: no-repeat;
      background-position: 0px 90px;
      background-size: 120px 120px, cover;
      margin-top: 50px;
    }
    .test2-responsive-400 {
      background-image: url(../../assets/image/1x/Asset_6.png);
      background-repeat: no-repeat;
      background-position: 280px 100px!important;
      background-size: 60px 60px;
    }
  }
  
  @media screen and (max-width: 428px) {
    .testi-responsive-428 {
      background-image: url(../../assets/image/1x/Asset_8.png);
      background-repeat: no-repeat;
      background-position: 0px 0px;
      background-size: 70px;
    }
    .test1-responsive-428 {
      background-image: url(../../assets/image/1x/Asset_7.png);
      background-repeat: no-repeat;
      background-position: 0px 90px;
      background-size: 120px 120px, cover;
      margin-top: 50px;
    }
    .test2-responsive-428 {
      background-image: url(../../assets/image/1x/Asset_6.png);
      background-repeat: no-repeat;
      background-position: 300px 100px;
      background-size: 60px 60px;
    }
  }
  
  .w-85{
   width: 95% !important;
  }