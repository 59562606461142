.test-card {
    display: block;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22); */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px ; */
    box-shadow: 0 0 8px rgba(33,33,33,.2); 
  }

  .button-card{
    width: 20px;
    height: 30px;
    font-size: 12px!important;
    color: #00AEEF!important;
    border: none!important;
    background-color: transparent!important;
    margin-top: 20px;
    margin-left: -10px!important;
}

.card-animation1 {
    color: #212529;
    border: none;
    width: 340px;
    height: 350px!important;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 1s; 
    transition: all 0.1s ease-in-out;
    text-overflow: hidden; 
  }
  .card-animation1:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px ;

  }

  .card-icon{
    width: 90px;
    height: 90px;
  }

  .card-icon-animation {
    animation-name: card-icon-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: white;
  }
  
  @keyframes card-icon-animation {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }

  .card-icon-animation2 {
    animation-name: card-icon-animation2;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: white;
  }
  
  @keyframes card-icon-animation2 {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 10px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }

  .card-icon-animation3 {
    animation-name: card-icon-animation2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: white;
  }
  
  @keyframes card-icon-animation3 {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 7px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }

  

  .card-title-margin{
    margin-top: 30px!important;
    font-size: 23px!important;
    font-family: "Poppins", sans-serif;
  }

  /* .test-card-margin{
    margin-left: 20px;
  }
  .test-card-cont{
    margin-left: -10px!important;
  }
  .test-card-coll{
    margin-left: 10px ;
  } */

  .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px!important;
    height: 10px!important;
    border-radius: 50%;
    padding: 10px;
    content: '';
    background: #00aeef!important;
    text-align: center;
  
}

  @media screen and (max-width: 360px) {
  .card-responsive360{
      margin-left: -10px!important;
    }
  }
  @media screen and (max-width: 400px) {
    .card-responsive400{
        margin-left: 10px!important;
      }
    }
  
  @media screen and (max-width: 375px) {
    .card-responsive375{
       margin-left: -12px;
      }
    }
    @media screen and (max-width: 390px) {
      /* .card-responsive-414{
      margin-left: 50px!important;
        } */
      }

      @media only screen and (min-width: 1440px) {
        .card-responsive-1440{
          margin-left: 20px;
         } 
    }