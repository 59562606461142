@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Lato', sans-serif;
}

p{
  font-size: 15px;
  font-family: 'Lato', sans-serif;
};

h1{
  font-family: 'Lato', sans-serif!important;
}
