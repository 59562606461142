.develop-card{
    display: block;
    padding: 45px 0;
    /* background: linear-gradient(180deg, rgba(255,255,255,1) 18%, rgba(240,253,249,1) 30%, rgba(233,253,255,1) 52%);     */
    border-radius: 10px 10px 10px 10px!important;
    box-shadow: 0 1px 3px rgba(44, 230, 205, 0.12), 0 1px 2px rgba(9, 231, 202, 0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);  
    box-shadow: 0 5px 10px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
    
}

.develop-animation1 {
    color: #212529;
    border: none;
    -webkit-transition: background 1s; /* For Safari 3.0 to 6.0 */
    transition: all .1s ease-in-out;
}
.develop-animation1:hover {
    transform: scale(1.02);
}

.develop-align{
    text-align: center;
}


.develop-card1-button:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #00aeef !important;
    color: #fff!important;

  }
  
  .develop-card1-button {
    color: #00aeef !important;
    font-size: small;
    background-color: #ffffff !important;
    border-radius: 10px !important;
    width: 150px;
    height: 50px;
    border-color: #00AEEF;
    border: 1px solid #00AEEF;
  }
  .develop-card2-button {
    color: #ffffff !important;
    font-size: small;
    background-color: #00aeef !important;
    border-radius: 10px !important;
    width: 150px;
    height: 50px;
  }
  .card-text-margin{
    margin-left: 30px;
  }
  

  /* .develop-card-button {
    color: white !important;
    font-size: small;
    background-color: #00aeef !important;
    border-radius: 10px !important;
    border-color: transparent !important;
    width: 150px;
    height: 50px;
  } */
  
.develop-span{
    color: #00AEEF;
}