input { 
    border-radius: 10px!important;
    margin: 4px!important;
    border:  0.2px solid #85dfff !important ;
  }
  textarea { 
    border-radius: 10px!important;
    margin: 4px!important;
    border:  0.2px solid #85dfff  ;
  }
  .button-ddevops {
    padding: 7px 15px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: #00AEEF!important;
    border: none;
    border-radius: 10px;
    width: 450px;
    margin-left: 50px;
    font-weight: bold;
  }
  .button-ddevops:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .contact-span{
    color: #00AEEF;
  }

  .contact-field{
    width: 450px;
    height: 40px;
    margin-left: 50px!important;
    margin-top: 40px!important;
  }

  .email-field{
    width: 450px;
    height: 40px;
    margin-left: 50px!important;
  }

  .text-field{
    width: 450px;
    height: 100px;
    margin-left: 50px!important;

  }

  .contact-img {
    background-image: url(../../assets/image/1x/Asset_7.png);
    background-repeat: no-repeat;
    background-position: 30px 0px;
    background-size: 90px 90px;
    margin-top: 50px;
  }

  .message-img {
    background-image: url(../../assets/image/1x/Asset_6.png);
    background-repeat: no-repeat;
    background-position: 455px 180px;
    background-size: 90px 90px;
    margin-top: 50px;
  }
  .contact-heading{
    margin-left: 3rem ;
  }

 .contact-gradiant {
  background-image: url(../../assets/image/contact-bg.jpeg);
  /* background-image: -moz-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
  background-image: -webkit-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
  background-image: -ms-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
  background-image:  linear-gradient(180deg, #85dfff 0%, #00aeef 100%); */
  position: static;
  z-index: 1;
  padding: 160px 0px 125px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
 }

  @media screen and (max-width: 400px) {
    .contact-img {
      background-image: url(../../assets/image/1x/Asset_7.png);
      background-repeat: no-repeat;
      background-position: 20px 0px;
      background-size: 90px 90px;
      margin-top: 50px;
    }
    .request-responsive400{
      margin-right: 26px;
    }
    /* .button-ddevops400 {
      padding: 7px 15px;
      font-size: 20px;
      text-align: center;
      cursor: pointer;
      outline: none;
      color: #fff;
      background-color: #00AEEF;
      border: none;
      border-radius: 10px;
      width: 250px;
    }
    .contact-resposive400{
      width: 250px;
      height: 40px;
      margin-left: 50px!important;
      margin-top: 40px!important;
  }
  .email-responsive-400{
    width: 250px;
    height: 40px;
    margin-left: 50px!important;
  }
  .text-responsive-400{
    width: 250px;
    height: 100px;
    margin-left: 50px!important;
  } */
  .message-img-responsive400 {
    background-image: url(../../assets/image/1x/Asset_6.png);
    background-repeat: no-repeat;
    background-position: 250px 180px;
    background-size: 75px 90px!important;
    margin-top: 50px;
  }
  }
  
  @media screen and (max-width: 428px) {
    .contact-resposive428{
        width: 300px;
        height: 40px;
        margin-left: 30px!important;
        margin-top: 40px!important;
    }
    .email-responsive-428{
      width: 300px;
      height: 40px;
      margin-left: 30px!important;
    }
    .text-responsive-428{
      width: 300px;
      height: 100px;
      margin-left: 30px!important;
    }
    .message-img-responsive428 {
      background-image: url(../../assets/image/1x/Asset_6.png);
      background-repeat: no-repeat;
      background-position: 300px 180px;
      background-size: 90px 90px;
      margin-top: 50px;
    }
    .button-responsive428 {
      font-size: 20px;
      text-align: center;
      cursor: pointer;
      outline: none;
      color: #fff;
      background-color: #00AEEF;
      border: none;
      border-radius: 10px;
      width: 300px;
      margin-left: 30px;
    }
    }

    @media screen and (max-width: 375px) {
      .contact-resposive375{
          width: 220px;
          height: 40px;
          margin-left: 10px!important;
          margin-top: 40px!important;
      }
      .email-responsive-375{
        width: 220px;
        height: 40px;
        margin-left: 10px!important;
      }
      .text-responsive-375{
        width: 220px;
        height: 100px;
        margin-left: 10px!important;
      }
      .message-img-responsive375 {
        background-image: url(../../assets/image/1x/Asset_6.png);
        background-repeat: no-repeat;
        background-position: 250px 180px;
        background-size: 90px 90px;
        margin-top: 50px;
      }
      .button-responsive375 {
        padding: 7px 15px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        outline: none;
        color: #fff;
        background-color: #00AEEF;
        border: none;
        border-radius: 10px;
        width: 220px;
        margin-left: 10px;
        /* box-shadow: 0 9px #999; */
      }
      }



      @media only screen and (max-width: 991px) {
        /* / styles for browsers larger than 1440px; / */
        .letterbox-image-container{
          display: none;
        }
        .heading{
          text-align: center;
          font-size: 40px !important;
          
        }
        .contact-you{
          text-align: center;
          font-size: 20px;
         
        }
        .contact-form{
          margin-top: -82px;      
        }   
      }

      @media only screen and (min-width: 1440px) {
        /* / for sumo sized (mac) screens / */
        .message-img-responsive1440 {
          background-image: url(../../assets/image/1x/Asset_6.png);
          background-repeat: no-repeat;
          background-position: 520px 180px;
          background-size: 75px 90px!important;
          margin-top: 50px;
        }
        .contact-img1440 {
          background-image: url(../../assets/image/1x/Asset_7.png);
          background-repeat: no-repeat;
          background-position: 80px 0px;
          background-size: 90px 90px;
          margin-top: 50px;
        }
      }

      @media only screen and (min-width: 2000px) {
        /* / for sumo sized (mac) screens / */
       
      }
      @media only screen and (max-device-width: 480px) {
       /* / styles for mobile browsers smaller than 480px; (iPhone) / */
      
      }
      @media only screen and (device-width: 768px) {
       /* / default iPad screens / */
      
      }
      /* / different techniques for iPad screening / */
      @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
      /* / For portrait layouts only / */
      
      }
      
      @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
      /* / For landscape layouts only / */
      
      }