.devops-gradiant img{
    width: 100%;
    display: block;
  }
  .text-devops-p{
   color: white; 
  }

   /* animation css  */

   .f_dev {
    font-family: 'Lato', sans-serif;
  }

  .dev_700 {
    font-weight: 700;
  }  
  .dev_size_50 {
    font-size: 50px;
  }
  .dev_color {
    color: #fff;
  }
  .dev_height50 {
    line-height: 50px;
  }
.dev_20 {
    margin-bottom: 20px;
  }

  /* animation css  */

  .heading-devops-services {
    font-family: 'Lato', sans-serif;
      position: relative;
      font-size: 12px;
      line-height: 2em;
      letter-spacing: 2px;
      color: #ababab;
      text-transform: uppercase;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
  }

  .mark15{
    font-size: 160px;
    background-color: transparent;
    color: #00aeef;
    font-family: 'Lato', sans-serif;
    margin-left: 60px;
    margin-top: 30px;

}
.hr2 {
  border-left: 3px solid #00aeef;
  height: 115px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 130;
}

.devops-service-font12{
  font-weight: 500;
  font-family: 'Lato', sans-serif;
}

.envolved-margin{
  margin-left: 80px;
  font-weight: 600;
}

.company-span{
  color: #00aeef;
}

.offer-margin{
margin-top: 50px;
font-weight: 400;

}

.how-we-claim .content div {
  background-color: #faf6f3;
  padding: 20px 24px;
  border-radius: 5px;
  margin: 0 10px;
  position: relative;
}

.how-we-claim svg {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 40px;
  height: auto;
}

.how-we-claim .content div.second {
  background-color: #edf2fe;;
}
.how-we-claim .content div.third {
  background-color: #f0f5f4;
}
.how-we-claim .content div.fourth {
  background-color: #fff5f6;
}
.how-we-claim .content div.fifth {
  background-color: #fcf7ee;
}
.how-we-claim h5 {
  font-size: 15px;
  font-weight: normal;
}
.how-we-claim h4 {
  font-size: 24px;
  font-weight: bold;
}

.toppsbanner {
  position: relative;
  width: 100%;
  height: 330px;
  background-image:  url('../../assets/image/cover-6.webp'); /* Replace with your image URL */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.toppsbanner::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
}

.toppsbanner-content {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;
}

.toppsbanner-content h4 {
  color: white;
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease, font-size 0.3s ease;
}

.toppsbanner-content span {
  display: none;
  color: white;
  font-size: 0.8em; /* Smaller font size than h4 */
  transition: transform 0.3s ease, font-size 0.3s ease;
}

.toppsbanner:hover .toppsbanner-content {
  bottom: 45%;
  transform: translateY(45%);
}

.toppsbanner:hover .toppsbanner-content h4,
.toppsbanner:hover .toppsbanner-content span {
  transform: translateY(-20px);
}

.toppsbanner:hover .toppsbanner-content span {
  display: inline-block;
  margin-top: 10px;
}

.toppsbanner:hover::before {
  background: rgba(17, 158, 231, 0.8); /* Blue background with opacity */
}

@media screen and (max-width: 400px) {


  .how-we-claim .content div {
    margin: 0px;
  }

  .hr2 {
    border-left: 3px solid #00aeef;
    height: 115px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 130;
    display: none;
  }

  .heading-devops-services {
    font-family: 'Lato', sans-serif;
      position: relative;
      font-size: 9px;
      line-height: 2em;
      letter-spacing: 2px;
      color: #ababab;
      text-transform: uppercase;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
  }

}

@media screen and (max-width: 412px) {
  .hr2 {
    border-left: 3px solid #00aeef;
    height: 115px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 130;
    display: none;
  }

  .heading-devops-services {
    font-family: 'Lato', sans-serif;
      position: relative;
      font-size: 9px;
      line-height: 2em;
      letter-spacing: 2px;
      color: #ababab;
      text-transform: uppercase;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
  }

}