.secops-gradiant img{
  width: 100%;
  display: block;
  }
  .text-secops-p{
   color: white; 
  }

   /* animation css  */

   .f_sec {
    font-family: 'Lato', sans-serif;
  }

  .sec_700 {
    font-weight: 700;
  }  
  .sec_size_50 {
    font-size: 50px;
  }
  .sec_color {
    color: #fff;
  }
  .sec_height50 {
    line-height: 50px;
  }
.sec_20 {
    margin-bottom: 20px;
  }

  /* animation css  */

  .heading-devsecops-services {
    font-family: 'Lato', sans-serif;
      position: relative;
      font-size: 12px;
      line-height: 2em;
      letter-spacing: 2px;
      color: #ababab;
      text-transform: uppercase;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
  }

  .mark-dev{
    font-size: 160px;
    background-color: transparent;
    color: #00aeef;
    font-family: 'Lato', sans-serif;
    margin-left: 60px;
    margin-top: 30px;

}
.hr3 {
  border-left: 3px solid #00aeef;
  height: 143px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 130;
}

.devsecops-service-font12{
  font-weight: 500;
  font-family: 'Lato', sans-serif;
}

.envolved-devsecops-margin{
  margin-left: 80px;
  font-weight: 600;
}

.devsecops-company-span{
  color: #00aeef;
}

.devsecops-offer-margin{
margin-top: 30px;
font-weight: 400;

}

@media screen and (max-width: 400px) {

  .mark-dev {
    margin-left: 0px;
  }

  .hr3 {
    border-left: 3px solid #00aeef;
    height: 143px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 130;
    display: none;
  }

  .heading-devsecops-services {
    font-family: 'Lato', sans-serif;
      position: relative;
      font-size: 9px;
      line-height: 2em;
      letter-spacing: 2px;
      color: #ababab;
      text-transform: uppercase;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
  }

}