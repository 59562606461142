
.color {
    color: #00AEEF;
}

.strong{
    color: #f8f9fa;
}
.strong1{
    color: #918c8c;
}

.span1{
    color: #00AEEF;
}

.font{
   font-family: 'Lato', sans-serif;
    font-size:  130px;
    font-weight: 700;
    color: #00AEEF;
}
.font2{
   font-family: 'Lato', sans-serif;
    font-size: 65px;
    color: #00AEEF;
}
.font3{
   font-family: 'Lato', sans-serif;
    font-size: 50px;
    color: #00AEEF;
}
.font-pioneer{
   font-family: 'Lato', sans-serif;
    font-size: 14px;
    margin-left: 30px;
}
.margin-pioneer{
    margin-left: 30px;
    margin-top: -40px;
   font-family: 'Lato', sans-serif;

}

.bg-color{
    background-color: #E9FCFF ;
}
/* 
  @property --num2 {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
  }

  .indirect-count {
    animation: counter 5s infinite alternate ease-in-out;
    counter-reset: num2 var(--num2);
    font: 70px system-ui;
  }

.indirect-count::after {
    content: counter(num2);
  }
  
  @keyframes counter {
    from {
        --num2: 1;
      }
      to {
        --num2: 1000;
      }
  } */