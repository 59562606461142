/* Base styles for pricing area */
.pricing_area_four .sec_title {
  margin-bottom: 50px;
}

.pricing_area_four .sec_title h2 {
  margin-bottom: 0px;
}

.pricing_area_four .sec_title p {
  font-style: italic;
}

.sec_pad {
  padding: 120px 0px;
}

.hosting_title {
  max-width: 700px;
  margin: 0 auto 70px;
  font-family: 'Lato', sans-serif;
}

.hosting_title h2 {
  font-size: 40px;
  line-height: 55px;
  font-weight: 600;
  color: #2c2c51;
  margin-bottom: 15px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.hosting_title p {
  color: #7b7b93;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.price_info_two {
  box-shadow: 0px 4px 5px 0px rgba(12, 0, 46, 0.05);
}

.price_info_two .price_head {
  display: flex;
  width: 100%;
}

.price_info_two .price_head .p_head {
  flex: 1;
  text-align: center;
  background: #0ed1b3;
  padding: 31px 0px;
  color: #fff;
}

.price_info_two .price_head .p_head.pro {
  background: #6c84ee;
}

.price_info_two .price_head .p_head.enterprise {
  background: #ee6cda;
}

.price_info_two .price_head .p_head h5 {
  font-size: 20px;
  font-weight: 600;
}

.price_info_two .price_item {
  flex: 1;
  text-align: center;
  padding: 15px 0px;
  cursor: pointer;
}

.price_info_two > div:nth-child(odd) {
  background: #f8f8fa;
}

.price_info_two .price_item h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #222d39;
}

.price_info_two .pr_list {
  display: flex;
  width: 100%;
}

.price_info_two .price_btn {
  text-decoration: none;
  border-radius: 50px;
  color: white;
  background-color: #00aff0;
  padding: 10px 32px;
  line-height: 46px;
  margin: 40px 0px;
  transition: all 0.2s linear;
}

.price_info_two .price_btn:hover {
  background: #5e2ced;
}

.price_info_three .price_item h5 {
  color: #2c2c51;
}

.price_info_three .price_item:hover:before {
  color: #26da15;
}

.price_info_three .price_item .check {
  color: #0e79de;
}

.price_info_three .price_item .cros {
  color: #fc3f48;
}

.price_info_three .price_btn:hover {
  background: #0e79de;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .price_info_two .price_head, .price_info_two .pr_list {
    display: block;
  }

  .price_info_two .price_head .p_head, .price_info_two .price_item {
    width: 100%;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
  }

  .price_info_two .price_head .p_head {
    text-align: center;
  }


}

@media (max-width: 400px) {
  .hosting_title h2 {
    font-size: 31px;
    line-height: 40px;
  }
}

@media (max-width: 375px) {
  .hosting_title h2 {
    font-size: 31px;
    line-height: 40px;
  }
}
