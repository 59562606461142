/* src/components/FlipCard.css */

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 370px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateX(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .flip-card-front {
    background-image: linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    color: white;
  }
  
  .flip-card-back {
    color: white;
    transform: rotateX(180deg);
    background-size: cover;
    background-position: center;
  }
  
  .card-content {
    text-align: center;
  }
  
  .card-content h2 {
    margin: 0;
  }

  .card-content h4 {
    margin: 0 0 20px;
  }
  
  .card-content p {
    margin: 20px 0 0;
    text-align: justify;
    height: auto;
    min-height: 160px;
  }
  