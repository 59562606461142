h1 {
  font-family: 'Lato', sans-serif;
}
.develop-span{
    color: #00AEEF!important;
  font-family: 'Lato', sans-serif;

  }

.heading-develop{
    color: white;
    font-weight: 800;
    font-size: 45px;
  font-family: 'Lato', sans-serif;

}

.develoy-step {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .2s ease-in-out;
	transition: .2s ease-in-out;
}
.develoy-step:hover{
	-webkit-transform: scale(1.04);
	transform: scale(1.04);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.develop-gradiant{
    background-image: url(../../assets/image/develop-bg.jpeg);
    /* background-image: -moz-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -webkit-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -ms-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image:  linear-gradient(180deg, #85dfff 0%, #00aeef 100%); */
    position: static;
    z-index: 1;
    padding: 160px 0px 125px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .text-develop-p{
   color: white; 
  }

  /* animation css  */


  .d_700 {
    font-weight: 700;
  }  
  .d_size_50 {
    font-size: 50px;
  }
  .d_color {
    color: #fff;
  }
  .d_height50 {
    line-height: 50px;
  }
.md_20 {
    margin-bottom: 20px;
  }

  /* animation css  */

.p-develop{
    margin-top: 40px!important;
}

.img-width{
  width: 320px!important;
}

.floating4 {   
    animation-name: floating4; 
    animation-duration: 3s; 
    animation-iteration-count: infinite; 
    animation-timing-function: ease-in-out;
    color: white;
  } 
  
  @keyframes floating4 { 
    0% { transform: translate(0,  0px); } 
    50%  { transform: translate(0, 20px); } 
    100%   { transform: translate(0, -0px); }     
  } 

  .develop-card2-process2{
    width: 270px;
    height: 270px!important;
    box-shadow: 0 1px 1px rgba(44, 230, 205, 0.12), 0 1px 1px rgba(9, 231, 202, 0.24);
    transition: all 0.1s cubic-bezier(.25,.8,.25,1);  
    border-bottom-right-radius: 4em!important;
    border-top-left-radius: 4em!important;
    box-shadow: 2px 2px 5px #e1e1e1;
    background: linear-gradient(to left, white 50%, #00AEEF 50%);
    background-size: 200%;
    background-position: right;
}

.develop-card-animation4 {
    color: black;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 1s; /* For Safari 3.0 to 6.0 */
    transition: all .5s ease-in-out;
}
.develop-card-animation4:hover {
  background-position: left;
  color: white;
  
}

.develop-text-transition{
  font-size: 32px!important;
  font-weight: 500;  
  font-family: 'Lato', sans-serif;

}

.develop-page-button:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.develop-page-button {
  color: white !important;
  background-color: #00aeef !important;
  border-radius: 10px !important;
  border-color: transparent !important;
  width: 150px;
  height: 60px;
}

.everyone-online {
  background-image: url(../../assets/image/wpbg.jpeg);
  background-repeat: no-repeat;
  padding: 3rem;
  padding-bottom: 100px !important;
}

.everyone-online .container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

@media screen and (max-width: 400px) {

    .card-margin400{
        margin-top: 20px;
    }
    .eccomerce-responsive{
      width: 270px;
      height: auto;
      
    }
    .develop-text-transition{
      font-size: 22px!important;
      font-weight: 500;
      /* display: none; */
    }
}

@media screen and (max-width: 375px) {
    .develop-card-responsive{
      margin-left: 40px!important;
    }
    .develoy-step {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: .2s ease-in-out;
      transition: .2s ease-in-out;
    }
    .develoy-step:hover{
      -webkit-transform: scale(1.01);
      transform: scale(1.01);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .button-margin {
    text-align: center;
    }
}
