.about-gradiant{
    background-image: url(../../assets/image/home4/banner_bg.png);
    background-image: -moz-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -webkit-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image: -ms-linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    background-image:  linear-gradient(180deg, #85dfff 0%, #00aeef 100%);
    position: static;
    z-index: 1;
    padding: 160px 0px 125px;
    overflow: hidden;
  }
  .text-about-p{
   color: white; 
  }

   /* animation css  */

   .f_ab {
      font-family: 'Lato', sans-serif;

  }

  .ab_700 {
    font-weight: 700;
  }  
  .ab_size_50 {
    font-size: 50px;
  }
  .ab_color {
    color: #fff;
  }
  .ab_height50 {
    line-height: 50px;
  }
.mab_20 {
    margin-bottom: 20px;
  }

  /* animation css  */
.heading-aboutus {
    font-family: 'Lato', sans-serif;

    position: relative;
    font-size: 12px;
    line-height: 2em;
    letter-spacing: 2px;
    color: #ababab;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.mark12{
    font-size: 200px;
    background-color: transparent;
    color: #00aeef;
      font-family: 'Lato', sans-serif;

    margin-left: 70px;
    margin-top: 40px;

}
.hr1 {
  border-left: 3px solid #00aeef;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 130;
}
.tradional-font{
  font-weight: 300;
    font-family: 'Lato', sans-serif;

}
.busniness-text-400{
  text-align: center;
}
.span-color{
  color: #00aeef;
    font-family: 'Lato', sans-serif;

}
.business-font{
    font-family: 'Lato', sans-serif;

}
.business-p{
 margin-left: 30px; 
}


@media screen and (max-width: 400px) {

  .business-p{
    margin-left: 0px; 
   }

  .heading-aboutus {
      font-family: 'Lato', sans-serif;

      position: relative;
      font-size: 12px;
      line-height: 2em;
      letter-spacing: 2px;
      color: #ababab;
      text-transform: uppercase;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      
  }
  .mark12{
      font-size: 140px;
      background-color: transparent;
      color: #00aeef;
        font-family: 'Lato', sans-serif;

      margin-left: 10px;
      margin-top: 40px;
  
  }
  .hr1 {
    border-left: 3px solid #00aeef;
    height: 200px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 130;
    display: none;
  }
}